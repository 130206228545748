function colorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
}

function cleanupRichText(richText) {
  return richText.replace(/rgb\((.+?)\)/gi, (_, rgb) => {
    return (
      "#" +
      rgb
        .split(",")
        .map(str => parseInt(str, 10).toString(16).padStart(2, "0"))
        .join("")
    );
  });
}

function setCtaColors({ first, second }) {
  let ctas = [];

  if (first) {
    const slide = document.querySelector("[data-slide='1']");
    if (!slide) return;

    const cta = slide.querySelector("a.slide-cta");

    if (!cta) return;
    ctas.push(cta);
    cta.style.backgroundColor = colorLuminance(first, -0.1);
  }

  if (second) {
    const slide = document.querySelector("[data-slide='2']");
    if (!slide) return;
    const cta = slide.querySelector("a.slide-cta");

    if (!cta) return;
    ctas.push(cta);

    cta.style.backgroundColor = colorLuminance(second, -0.1);
  }

  for (var i = 0; i < ctas.length; i++) {
    const curr = ctas[i];
    const initialColor = cleanupRichText(curr.style.backgroundColor);
    console.log(curr);
    curr.addEventListener("mouseover", () => {
      curr.style.backgroundColor = colorLuminance(initialColor, -0.2);
    });

    curr.addEventListener("mouseout", () => {
      curr.style.backgroundColor = initialColor;
    });
  }
}

function addSlideHover() {
  const slides = document.querySelectorAll("[data-slide]");

  let windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  window.addEventListener("resize", () => {
    windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    if (windowWidth < 968) {
      slides.forEach(slide => {
        slide.classList.remove("no-active");
        slide.classList.remove("active");
      });
    }
  });

  for (var i = 0; i < slides.length; i++) {
    const slide = slides[i];
    const idx = i;
    slide.addEventListener("mouseover", function () {
      if (windowWidth < 968) return;
      slide.classList.remove("no-active");
      slide.classList.add("active");
      slide.style.zIndex = "9";
      const otherSlide = idx === 1 ? slides[0] : slides[1];
      otherSlide.classList.remove("active");
      otherSlide.classList.add("no-active");
      otherSlide.style.zIndex = "1";
    });

    slide.addEventListener("mouseout", function () {
      if (windowWidth <= 968) return;
      slide.classList.remove("active");

      slides.forEach(slide => {
        slide.classList.remove("no-active");
      });
    });
  }
}

function main() {
  const sp = global.spHero;
  if (!sp) return;
  const { slide_1_color, slide_2_color } = sp;
  setCtaColors({ first: slide_1_color, second: slide_2_color });
  addSlideHover();
}
document.addEventListener("DOMContentLoaded", main);
